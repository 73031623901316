<template>
	<div class="news">
		<BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
		<div class="about-main-box">
			<!-- 导航栏 -->
			<div class="crumbs">
				<div class="cru-logo">
					<img src="../../assets/images/home/daohang_logo.png" alt="">
				</div>
				<el-breadcrumb separator=">">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>新闻中心</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="all-introduce">
				<div class="list-box" v-for="item in newList" :key="item.id" @click="changepage(item.id)">
					<div class="left-box">
						<div class="news-title">{{item.title}}</div>
						<div class="news-content">{{item.description}}</div>
						<div class="notice">
							<span>了解详情+</span>
							<!-- 手机端 -->
							<div class="sj_time">
								<span>{{item.create_time}}</span>
							</div>
						</div>
					</div>
					<div class="right-box">
						<div class="time">{{item.date}}</div>
						<span>{{item.time}}</span>
					</div>

				</div>

				<div class="page-num">
					<el-pagination @current-change='handleCurrentChange' background layout="prev, pager, next"
						:total="total" :page-size="pageSize" next-text="下一页">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import BannerOther from '@/components/banner-other/index.vue'
	import {
		getxinwenList
	} from '@/api/joggle.js'
	export default {
		name: 'News',
		components: {
			BannerOther
		},
		data() {
			return {
				img_url:'',
				chinese_name: '新闻中心',
				english_name: 'NEWS CENTER',
				total: 0, // 总数量
				pageSize: 10, // 每页数量
				page_no: 1, //当前页
				newList:[]
			}
		},
		activated() {
			var that = this
			// 获取头图(栏目图)
			var company_info = JSON.parse(localStorage.getItem('company_info'));
			if(company_info){
				that.img_url = company_info.news_image;
			}
			that.getList();
		},
		mounted() {

		},
		methods: {
			getList() {
				var that = this
				var obj = {
					page_no:that.page_no
				}
				getxinwenList(obj).then(res => {
					if (res.code == 1) {
						var data = res.data;
						that.total = data.count;
						that.pageSize = data.page_size;
						that.newList = data.list;
						that.newList.forEach((item,index) => {
							var num = item.create_time.lastIndexOf('-');
							if(num != -1){
								item.date = item.create_time.slice(num + 1)
								item.time = item.create_time.slice(0,num)
							}
						})
					} else {

					}
				})
			},
			// 分页
			handleCurrentChange(val) {
				var that = this
				// 当前页
				that.page_no = val
				that.getList();
			},
			changepage(id) {
				var that = this
				that.$router.push({
					path: '/news-details',
					query:{
						id:id
					}
				})
			}
		},
		// 离开页面事件
		beforeDestroy() {},
	}
</script>

<style lang="scss">
	@import '../../assets/css/news.scss';
	@import '../../assets/css/public.scss';
</style>
